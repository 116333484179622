import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Iron Butt Association Rides")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"pink"},on:{"click":function($event){return _vm.initRoute()}}},[_vm._v("TEST")])],1),_c(VCardText,[_c(VDataTable,{staticClass:"mt-4",attrs:{"dense":"","headers":_vm.tableHeaders,"items":_vm.RidesSortedByOrder,"item-key":"rideid","disable-pagination":"","hide-default-footer":"","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v("0")]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.points))]),_c('td',[_vm._v(_vm._s(item.map.distance))]),_c('td',[_vm._v("1")]),_c('td',[_vm._v("2")]),_c('td',[_vm._v("3")]),_c('td',[_vm._v("4")]),_c('td')])]}}])}),_c(VBtn,{attrs:{"color":"pink","dark":"","absolute":"","bottom":"","right":"","fab":""},on:{"click":function($event){_vm.dialogAddRide = true}}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }