<template>
  <v-container>
    <v-card elevation="2">
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Iron Butt Association Rides</v-toolbar-title
        ><v-spacer></v-spacer>
        <v-btn color="pink" @click="initRoute()">TEST</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          dense
          :headers="tableHeaders"
          :items="RidesSortedByOrder"
          item-key="rideid"
          disable-pagination
          hide-default-footer
          show-expand
          single-expand
          class="mt-4"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>0</td>
              <td>{{ item.name }}</td>
              <td>{{ item.points }}</td>
              <td>{{ item.map.distance }}</td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
        <v-btn
          color="pink"
          dark
          absolute
          bottom
          right
          fab
          @click="dialogAddRide = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Rides',
  data() {
    return {
      tableHeaders: [
        { text: 'Start date', sortable: false, value: 'startdate' },
        { text: 'Ride type', sortable: false, value: 'ridetype' },
        { text: 'Distance', sortable: false, value: 'gpsdistance' },
        { text: 'Total time', sortable: false, value: 'totaltime' },
        { text: 'Moving time', sortable: false, value: 'movingtime' },
        { text: 'Moving average', sortable: false, value: 'movingaverage' },
        { text: 'Overall average', sortable: false, value: 'overallaverage' },
        { text: 'Fuel consumption', sortable: false, value: 'fuelconsumption' }
      ]
    }
  },
  computed: {
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser,
      UserProfile: (state) => state.moduleUser.UserProfile,
      Preferences: (state) => state.modulePreferences.Preferences
    })
  },
  created: function () {
    this.RouteStopsReadAllAction()
  },
  methods: {}
}
</script>

<style scoped>
.v-data-table th {
  font-size: 12px;
}
.v-data-table td {
  font-size: 12px;
}
</style>
